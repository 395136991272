import React from "react";
import { HeaderContainer, HeaderSubtitle, HeaderAspiration, HeaderTitle, HeaderMenu, HeaderIntrodution, HeaderMenuItem } from "./styledComponents";
//import './style.css';

export default class Header extends React.Component {
    render(){
        return(
            <HeaderContainer>
                <HeaderIntrodution>
                    <HeaderTitle>Jesus Abrahão</HeaderTitle>
                    <HeaderSubtitle>Developer</HeaderSubtitle>
                    <HeaderAspiration>Software developer with programming skills and creative solutions. Proven track record of delivering innovative and engaging experiences. My current professional objetive is working as a Game developer.</HeaderAspiration>
                </HeaderIntrodution>
            </HeaderContainer>
        );
    }
}
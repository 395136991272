import React from "react";
import EyeImg from "../../assets/EyeTracking.png";
import Kinect from "../../assets/Kinect.png";
import { BodyContainer, ExperiencesContainer, ExpirienceLabelItem, ExpirienceItemText, LinkContainer, ResumeContainer, ResumeLink, ResumeText, ExpirienceItem, ExpirienceTecnologies, ExpirienceItemTextBold, PartTitle, ProjectsContainer, ProjectItem, ProjectContent, ProjectTitle, ProjectImage } from "./styledComponents";

export default class Body extends React.Component {
    render(){
        return(
            <BodyContainer>
                <ResumeContainer>
                    <PartTitle>
                        RESUME:
                    </PartTitle>
                    <ResumeText>I am a 3D application developer with 4 years of experience, who is passionate about technology and its applications, particularly in the field of gaming. I have worked on a range of solutions and have experience using game engines such as Unreal, Unity, Godot, BabylonJS, and ThreeJS. My journey began in assistive technologies at Labiras Laboratory, and I currently work as an Unreal Developer at +A Educação-Imersys. I enjoy working collaboratively in groups to solve problems and learn new ways of developing both professionally and personally.</ResumeText>
                    <LinkContainer>
                    <ResumeText>My full resume:</ResumeText>
                    <ResumeLink href="https://drive.google.com/file/d/1fldlnrjDDyC3eQXH6F2d0qAME0LweRv2/view?usp=sharing">Resume</ResumeLink>
                    </LinkContainer>
                </ResumeContainer>
                <ExperiencesContainer>
                    <PartTitle>
                        EXPERIENCE:
                    </PartTitle>
                    <ExpirienceItem>
                        <ExpirienceLabelItem>
                            <ExpirienceItemText>August 2022 - Moment -</ExpirienceItemText>
                            <ExpirienceItemText>Unreal Developer at +A Educação-Imersys</ExpirienceItemText>
                        </ExpirienceLabelItem>
                        <ExpirienceItemText>Tecnologies used: </ExpirienceItemText>
                        <ExpirienceTecnologies>
                            <ExpirienceItemTextBold>Unreal</ExpirienceItemTextBold>
                        </ExpirienceTecnologies>
                    </ExpirienceItem>
                    <ExpirienceItem>
                        <ExpirienceLabelItem>
                            <ExpirienceItemText>February 2020 - August 2022 - </ExpirienceItemText>
                            <ExpirienceItemText>Unreal Developer at Xsensors</ExpirienceItemText>
                        </ExpirienceLabelItem>
                        <ExpirienceItemText>Tecnologies used: </ExpirienceItemText>
                        <ExpirienceTecnologies>
                            <ExpirienceItemTextBold>Unreal</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Unity</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Godot</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Three.js</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Babylon.js</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>React</ExpirienceItemTextBold>
                        </ExpirienceTecnologies>
                    </ExpirienceItem>
                    <ExpirienceItem>
                        <ExpirienceLabelItem>
                            <ExpirienceItemText>January 2017 - December 2021. - </ExpirienceItemText>
                            <ExpirienceItemText>Course in Systems Analysis and Development.</ExpirienceItemText>
                        </ExpirienceLabelItem>
                        <ExpirienceItemText>Tecnologies used: </ExpirienceItemText>
                        <ExpirienceTecnologies>
                            <ExpirienceItemTextBold>Python</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Java</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>Android</ExpirienceItemTextBold>
                            <ExpirienceItemTextBold>C</ExpirienceItemTextBold>
                        </ExpirienceTecnologies>
                    </ExpirienceItem>
                </ExperiencesContainer>
                <ProjectsContainer>
                    <PartTitle>
                        PROJECTS:
                    </PartTitle>
                    <ProjectItem>
                        <ProjectImage src={EyeImg}/>
                        <ProjectContent>
                            <ProjectTitle>Eye Tracking in Framework</ProjectTitle>
                            <ProjectContent>
                                This project involves crafting a groundbreaking framework to simplify the development of accessible games. Using state-of-the-art eye tracking technology, the aim is to transform gaming experiences for those with motor disabilities. By facilitating gameplay solely through eye movements, the project strives to offer an inclusive and immersive platform, breaking barriers and providing a distinctive accessible avenue for interactive entertainment.
                            </ProjectContent>
                        </ProjectContent>
                    </ProjectItem>
                    <ProjectItem>
                        <ProjectImage src={Kinect}/>
                        <ProjectContent>
                            <ProjectTitle>Motor Rehabilitation and Biotelemetry Data Acquisition with Kinect</ProjectTitle>
                            <ProjectContent>
                                The project's objective is to create a system that, when combined with an exercise game (exergame), captures and transmits in-game motion data. This facilitates a more efficient and swift analysis of a patient's progress, employing visual aids such as graphs and tables. The system aims to enhance the evaluation of physiotherapeutic treatments by providing comprehensive visual indicators. The incorporation of an exergame not only promotes greater patient adherence to treatment but also contributes to overall well-being improvements.
                            </ProjectContent>
                        </ProjectContent>
                    </ProjectItem>
                </ProjectsContainer>
            </BodyContainer>
        );
    }
}